import { PredefinedApplication } from './application.model';

export interface ApplicationDto {
  id?: number;
  protocol?: ApplicationAuthProtocol;
  name?: string;
  description?: string;
  logo?: string;
  logoUrl?: string;
  showInUserPortal?: boolean;
  ssoProtocolConfig: ApplicationSsoProtocolConfigDto;
  attributeMapping?: AssertionAttributeDto[];
  loginFlow?: AdditionalAuthenticationFactor[];
  created_at?: Date;
  status?: string;
  linkedApplicationIds?: number[];
  linkedApplications?: { id: number; name: string; logoUrl: string }[];
  predefinedApplicationId?: number;
  predefinedApplication?: PredefinedApplication;
  provisioningSettings?: ProvisioningSettings;
  provisioning?: provisioningDto;
  oidcAuthorizeUrl?: string;
  jwksUri?: string;
}

export interface provisioningDto {
  oauthApplicationCredentialsId?: number;
  customAttributes?: { [key: string]: string };
}

export interface ProvisioningSettings {
  provisioningCredentialsType?: ProvisioningCredentialsType;
  provisioningStrategyName?: string;
  oauthAuthorizeEndpoint?: string;
  customAttributes?: ProvisioningSettingsCustomAttributes[];
}

export interface ProvisioningSettingsCustomAttributes {
  attributeFieldDisplayName: string;
  attributeFieldName: string;
}

export enum ProvisioningCredentialsType {
  OAUTH = 'OAUTH',
  API_KEY = 'API_KEY',
}

export interface ApplicationSsoProtocolConfigDto {
  saml: ApplicationSamlProtocolConfigDto;
  oidc: ApplicationOidcProtocolConfigDto;
}

export interface AssertionAttributeDto {
  externalAttributeName?: string;
  internalAttributeName?: UserProfileAttribute;
}

export interface ApplicationSamlProtocolConfigDto {
  spEntityId?: string;
  ACSUrl?: string;
  audienceUrl?: string;
  logoutUrl?: string;
  signResponse?: boolean;
  signAssertion?: boolean;
  nameId?: SamlNameIdAttribute;
  nameIdFormat?: SamlNameIdFormat;
}

export interface ApplicationOidcProtocolConfigDto {
  clientId?: string;
  redirectUris?: string[];
  allowedLogoutRedirectUris?: string[];
  accessTokenExpiry?: number;
  refreshTokenExpiry?: number;

  subject?: OidcSubjectAttribute;
  clientCredentialGrant?: boolean;
}

export enum AdditionalAuthenticationFactor {
  NONE = '',
  OTP = 'OTP',
  PORTRAIT = 'PORTRAIT',
  FINGERPRINT = 'FINGERPRINT',
}

export enum ApplicationAuthProtocol {
  SAML = 'SAML',
  OIDC = 'OIDC',
}

export enum UserProfileAttribute {
  USERNAME = 'username',
  EMAIL = 'email',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  ORGANIZATION_ID = 'organizationId',
}

export enum OidcSubjectAttribute {
  USERNAME = 'username',
  EMAIL = 'email',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
}
export enum SamlNameIdAttribute {
  USERNAME = 'username',
  EMAIL = 'email',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
}

export enum SamlNameIdFormat {
  USERNAME = 'username',
  EMAIL = 'email',
  TRANSIENT = 'transient',
  PERSISTENT = 'persistent',
}
